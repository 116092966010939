import { RichText } from 'prismic-dom'
import { filter, map } from 'lodash' /* eslint-disable-line import/named */

export const mapSliceDataToPayload = (
  sliceData: PrismicHighlightSliceData
) => ({
  background: sliceData.primary.background,
  content: RichText.asHtml(sliceData.primary.content),
  icon: sliceData.primary.highlight_icon,
  image: sliceData.primary.highlight_image && {
    alt: sliceData.primary.highlight_image.name,
    filename: sliceData.primary.highlight_image.cdn_link,
  },
  title: RichText.asHtml(sliceData.primary.highlight_title),
  items: map(sliceData.items, (item) => ({
    icon: item.item_icon,
    image: item.item_image && {
      alt: item.item_image.name,
      filename: item.item_image.cdn_link,
    },
    text: RichText.asHtml(item.item_text),
  })),
})

export const sliceLookup = (
  document: { body?: PrismicHighlightSliceData[] },
  sliceType: string,
  sliceLabel?: string
) => {
  if (!document) return []

  const { body } = document

  const slices = filter(
    body,
    (slice: PrismicHighlightSliceData) => slice.slice_type === sliceType
  )

  if (!sliceLabel) {
    return filter(
      slices,
      (slice: PrismicHighlightSliceData) => slice.slice_label === null
    )
  }

  return filter(
    slices,
    (slice: PrismicHighlightSliceData) => slice.slice_label === sliceLabel
  )
}

export const extractHighlightPayloadFromBody = (
  document: { body?: PrismicHighlightSliceData[] },
  sliceLabel?: string
) => {
  const slices = sliceLookup(document, 'highlightv2', sliceLabel)

  return map(slices, (slice) => mapSliceDataToPayload(slice))
}
