import { render, staticRenderFns } from "./detail-adresse.vue?vue&type=template&id=504575f1"
import script from "./detail-adresse.vue?vue&type=script&setup=true&lang=ts"
export * from "./detail-adresse.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./detail-adresse.vue?vue&type=style&index=0&id=504575f1&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsInputBase: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/atoms/input-base/input-base.vue').default,AtomsInputDropdown: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/atoms/input-dropdown/input-dropdown.vue').default,Step: require('/vercel/path0/components/Step.vue').default,OrganismsHighlightV2: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/organisms/highlight-v2/highlight-v2.vue').default})
